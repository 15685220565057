.home {
  background: linear-gradient(
    180deg,
    #ffed8e 0%,
    rgba(255, 237, 141, 0.6) 100%
  );

  .info {
    position: absolute;
    bottom: 80px;
  }
}
